import {
  Button,
  Logo,
  Stack,
  useTranslation,
} from "@data-products-and-ai/react-components";
import styles from "./Login.module.scss";
import Background from "./assets/background.jpg";
import { AzureLoginButton } from "./components/AzureLoginButton";
import { LoginTitle } from "./components/LoginTitle";

const Login = () => {
  const translate = useTranslation();

  const handleEmail = () => {
    const mail = document.createElement("a");
    mail.href = `mailto:pavel.gocev@siemens-energy.com?subject=${translate(
      "LOGIN.REQUEST_ACCESS_EMAIL_SUBJECT"
    )}`; /* ${LOGIN.REQUEST_ACCESS_EMAIL_SUBJECT} */
    mail.click();
    mail.remove();
  };

  return (
    <div
      className={styles.loginContainer}
      style={
        {
          "--bg-image": `url(${Background})`,
        } as React.CSSProperties
      }
    >
      <div className={styles.loginFormContainer}>
        <Logo background="light" size="medium" />
        <LoginTitle />

        <div style={{ marginTop: 15 }}>
          <AzureLoginButton />
        </div>

        <div className={styles.loginFormAdditional}>
          {/* Make_reusable divider  */}
          <hr />

          <Stack direction="row" horizontalAlign="center">
            <Button type="link" onClick={handleEmail} allCaps={false}>
              {translate("LOGIN.REQUEST_ACCESS")}
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Login;

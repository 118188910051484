export const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_MSAL_CLIENT_ID, // Read from environment variables
    authority:
      "https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477",
    redirectUri: import.meta.env.VITE_MSAL_REDIRECT_URI, // Read from environment variables
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import BrandCard from "./BrandCard";
import BrandDetails from "./BrandDetail";

interface Brand {
  BRAND_ID: number;
  BRAND_NAME: string;
  IS_RELATED?: boolean;
  RELATED_BY?: string;
}

interface RelatedBrandsComponentProps {
  selectedBrand: Brand | null;
  relatedBrands: Brand[];
  loadingRelated: boolean;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
  textAlign: "center",
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: "none",
  maxHeight: "80vh",
  overflowY: "scroll",
}));

const StickyBox = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: 0,
  zIndex: 1,
  backgroundColor: theme.palette.background.paper,
  paddingBottom: theme.spacing(1),
}));

const RelatedBrandsComponent: React.FC<RelatedBrandsComponentProps> = ({
  selectedBrand,
  relatedBrands,
  loadingRelated,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalBrand, setModalBrand] = useState<Brand | null>(null);

  const filteredRelatedBrands = relatedBrands.filter((brand) =>
    brand.BRAND_NAME.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleEyeClick = (brand: Brand) => {
    setModalBrand(brand);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalBrand(null);
  };

  return (
    <StyledPaper>
      {loadingRelated ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : !selectedBrand ? (
        <Typography variant="body1">
          Please search or select a brand to see related brands
        </Typography>
      ) : (
        <>
          <StickyBox>
            <TextField
              variant="outlined"
              placeholder="Search Related Brands"
              fullWidth
              sx={{ paddingTop: 2 }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchTerm && (
                      <IconButton onClick={handleClearSearch}>
                        <ClearIcon />
                      </IconButton>
                    )}
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </StickyBox>
          {filteredRelatedBrands.length === 0 ? (
            <Typography variant="body1">No related brands found.</Typography>
          ) : (
            filteredRelatedBrands.map((brand) => (
              <BrandCard
                onCardClick={() => {}}
                key={brand.BRAND_ID}
                brand={brand}
                onEyeClick={handleEyeClick}
              />
            ))
          )}
        </>
      )}

      <Dialog
        maxWidth="md"
        fullWidth
        open={modalOpen}
        onClose={handleModalClose}
      >
        <DialogTitle>Brand Information</DialogTitle>
        <DialogContent>
          {modalBrand && <BrandDetails brand={modalBrand} />}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleModalClose}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </StyledPaper>
  );
};

export default RelatedBrandsComponent;

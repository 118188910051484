import {
  useState,
  useCallback,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/material/styles";
import { useDebounceCallback } from "usehooks-ts";
import axios from "axios";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { Brand } from "@/types/brand";
import BrandCard from "./BrandCard";
import BrandDetail from "./BrandDetail";
import CloseIcon from "@mui/icons-material/Close";
import { acquireToken } from "@/utils";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
  textAlign: "center",
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: "none",
  maxHeight: "80vh",
  overflowY: "scroll",
}));

const StickyBox = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: 0,
  zIndex: 1,
  backgroundColor: theme.palette.background.paper,
  paddingBottom: theme.spacing(1),
}));

interface BrandSearchComponentProps {
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
  onBrandSelect: (brand: Brand | null) => void;
}

const BrandSearchComponent = forwardRef<
  { fetchData: () => Promise<void> },
  BrandSearchComponentProps
>(({ instance, accounts, onBrandSelect }, ref) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [loadingBrands, setLoadingBrands] = useState<boolean>(false);
  const [loadingAutocomplete, setLoadingAutocomplete] =
    useState<boolean>(false);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [defaultBrands, setDefaultBrands] = useState<Brand[]>([]);
  const [autocompleteOptions, setAutocompleteOptions] = useState<Brand[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalBrand, setModalBrand] = useState<Brand | null>(null);

  const searchBrands = useCallback(
    async (query: string) => {
      try {
        const tokenResponse = await acquireToken(instance, accounts);

        const headers = {
          Authorization: `Bearer ${tokenResponse.accessToken}`,
          "Content-Type": "application/json",
        };

        const url = new URL(
          `${
            import.meta.env.VITE_APP_API_URL
          }/brandmanagementservice/get_selected_brands`
        );
        url.searchParams.append("limit", "3000");
        if (query && query.trim() !== "") {
          url.searchParams.append("selected_brand_name", query);
        }

        const response = await axios.get(url.toString(), { headers });
        const data: Brand[] = response.data.data;
        setAutocompleteOptions(data);
        return data;
      } catch (error) {
        console.error("Error fetching brands:", error);
        return [];
      } finally {
        setLoadingBrands(false);
        setLoadingAutocomplete(false);
      }
    },
    [instance, accounts]
  );

  const debouncedSearch = useDebounceCallback(searchBrands, 500);

  useEffect(() => {
    fetchData();
  }, [instance, accounts, searchBrands]);

  const fetchData = useCallback(async () => {
    setLoadingBrands(true);
    const data = await searchBrands("");
    setDefaultBrands(data);
    setAutocompleteOptions(data);
  }, [searchBrands]);

  useImperativeHandle(ref, () => ({
    fetchData,
  }));

  const handleSearchChange = (_event: any, value: string, reason: string) => {
    setInputValue(value);
    setLoadingAutocomplete(true);
    if (reason === "input" && value.length >= 2) {
      debouncedSearch(value);
    }
  };

  const clearSearch = () => {
    setInputValue("");
    setAutocompleteOptions(defaultBrands);
    setSelectedBrand(null);
    onBrandSelect(null);
  };

  const handleBrandClick = (brand: Brand) => {
    setSelectedBrand(brand);
    onBrandSelect(brand);
    setInputValue(brand.BRAND_NAME);
  };

  const handleEyeClick = (brand: Brand) => {
    setModalBrand(brand);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalBrand(null);
  };

  return (
    <StyledPaper>
      <StickyBox>
        <Autocomplete
          options={autocompleteOptions}
          value={selectedBrand}
          getOptionLabel={(option: Brand) => option.BRAND_NAME}
          inputValue={inputValue}
          onInputChange={(event, value, reason) =>
            handleSearchChange(event, value, reason)
          }
          onChange={(_event, value: Brand | null) => handleBrandClick(value!)}
          noOptionsText={
            loadingAutocomplete ? "Loading brands..." : "No results found"
          }
          renderOption={(props, option) => (
            <li {...props} key={option.BRAND_ID}>
              {option.BRAND_NAME}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ paddingTop: 2 }}
              variant="outlined"
              placeholder="Search"
              fullWidth
              InputProps={{
                ...params.InputProps,
                style: { paddingRight: "1rem" },
                endAdornment: (
                  <InputAdornment position="end">
                    {inputValue && (
                      <IconButton onClick={clearSearch}>
                        <ClearIcon />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={() =>
                        inputValue.length >= 3 && searchBrands(inputValue)
                      }
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </StickyBox>

      {loadingBrands ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : (
        <Box mt={2}>
          {selectedBrand ? (
            <BrandCard
              key={selectedBrand.BRAND_ID}
              brand={selectedBrand}
              onEyeClick={handleEyeClick}
              onCardClick={handleBrandClick}
              selected={true}
            />
          ) : (
            defaultBrands.map((brand) => (
              <BrandCard
                key={brand.BRAND_ID}
                brand={brand}
                onEyeClick={handleEyeClick}
                onCardClick={handleBrandClick}
                selected={false}
              />
            ))
          )}
        </Box>
      )}

      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        maxWidth={false}
        PaperProps={{
          sx: {
            width: "95vw",
            height: "95vh",
            maxWidth: "95vw",
            maxHeight: "95vh",
          },
        }}
        fullWidth
      >
        <DialogTitle>
          Brand {modalBrand?.BRAND_NAME}
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {modalBrand && <BrandDetail brand={modalBrand} />}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleModalClose}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </StyledPaper>
  );
});

export default BrandSearchComponent;

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Slide,
  IconButton,
  Dialog,
  Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TransitionProps } from "@mui/material/transitions";
import axios from "axios";
import { Brand } from "@/types/brand";
import { useMsal } from "@azure/msal-react";
import { acquireToken } from "@/utils";
import AccountsTable from "./AccountsTable";
import RelatedAccountsTable from "./RelatedAccountsTable";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";

const SVGRenderer: React.FC<{ url: string }> = ({ url }) => {
  const [svg, setSvg] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetch(url)
      .then((res) => res.text())
      .then((text) => {
        setSvg(text);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching SVG:", err);
        setLoading(false);
      });
  }, [url]);

  if (loading) {
    return <CircularProgress />;
  } else if (!svg) {
    return <Typography color="error">Failed to load SVG</Typography>;
  }
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: svg }}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& svg": {
          width: "100%",
          height: "100%",
          maxWidth: "200px",
          maxHeight: "200px",
        },
      }}
    />
  );
};

interface BrandDetailsProps {
  brand: Brand;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BrandDetail: React.FC<BrandDetailsProps> = ({ brand }) => {
  const [proof, setProof] = useState<{
    fileUrl: string;
    fileExtension: string;
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [openFullscreen, setOpenFullscreen] = useState<boolean>(false);
  const { instance, accounts } = useMsal();
  const [activeTab, setActiveTab] = useState<string>("details");

  useEffect(() => {
    const fetchProof = async () => {
      try {
        setLoading(true);
        const tokenResponse = await acquireToken(instance, accounts);
        const headers = {
          Authorization: `Bearer ${tokenResponse.accessToken}`,
          "Content-Type": "application/json",
        };
        const response = await axios.get<{
          fileUrl: string;
          fileExtension: string;
        }>(
          `${
            import.meta.env.VITE_APP_API_URL
          }/filemanagementservice/get_presigned_url`,
          {
            params: { brand_id: brand.BRAND_ID },
            headers,
          }
        );
        setProof(response.data);
      } catch (error) {
        console.error("Error fetching brand details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (brand) {
      fetchProof();
    }
  }, [brand, instance, accounts]);

  const handleOpenFullscreen = () => setOpenFullscreen(true);
  const handleCloseFullscreen = () => setOpenFullscreen(false);

  const renderImage = (isFullscreen: boolean = false) => {
    if (!proof) return null;

    const commonSx = {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    if (proof.fileExtension.toLowerCase() === "svg") {
      return (
        <Box sx={commonSx}>
          <SVGRenderer url={proof.fileUrl} />
        </Box>
      );
    } else {
      return (
        <Box
          component="img"
          src={proof.fileUrl}
          alt={`${brand.BRAND_NAME} image`}
          sx={{
            ...commonSx,
            objectFit: "contain",
            maxWidth: isFullscreen ? "90vw" : "200px",
            maxHeight: isFullscreen ? "90vh" : "200px",
          }}
        />
      );
    }
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <Box
      display="flex"
      sx={{ gap: "2rem", width: "100%" }}
      flexDirection="column"
      alignItems="center"
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
            <TabList
              onChange={handleChange}
              aria-label="Brand Details Tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Details" value="details" />
              <Tab label="Accounts" value="accounts" />
              <Tab label="Related Accounts" value="related_accounts" />
            </TabList>
          </Box>
          <TabPanel keepMounted value="details" sx={{ width: "100%" }}>
            <Typography variant="body1">ID: {brand.BRAND_ID}</Typography>
            {proof?.fileUrl ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                position="relative"
                width="100%"
                maxWidth="500px"
                sx={{
                  height: "18rem",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  overflow: "hidden",
                  backgroundColor: "transparent",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "gray",
                    padding: "1rem",
                  }}
                >
                  {renderImage()}
                </Box>
                <Box
                  position="absolute"
                  bottom="0"
                  width="100%"
                  bgcolor="rgba(0, 0, 0, 0.2)"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  padding="8px"
                >
                  <IconButton
                    onClick={handleOpenFullscreen}
                    aria-label="fullscreen"
                    sx={{ color: "white" }}
                  >
                    <FullscreenIcon />
                  </IconButton>
                </Box>

                <Dialog
                  open={openFullscreen}
                  onClose={handleCloseFullscreen}
                  maxWidth={false}
                  fullScreen
                  TransitionComponent={Transition}
                >
                  <Box
                    sx={{
                      width: "100vw",
                      height: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(0, 0, 0, 0.9)",
                    }}
                  >
                    {renderImage(true)}
                    <IconButton
                      onClick={handleCloseFullscreen}
                      aria-label="close"
                      sx={{
                        position: "absolute",
                        top: 16,
                        right: 16,
                        color: "white",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Dialog>
              </Box>
            ) : (
              <Typography variant="body1">Proof is not available</Typography>
            )}
          </TabPanel>
          <TabPanel keepMounted value="accounts" sx={{ width: "100%" }}>
            <AccountsTable brand={brand} />
          </TabPanel>
          <TabPanel keepMounted value="related_accounts" sx={{ width: "100%" }}>
            <RelatedAccountsTable brand={brand} />
          </TabPanel>
        </TabContext>
      )}
    </Box>
  );
};

export default BrandDetail;

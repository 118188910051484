import { useEffect, useState } from "react";
import { Box } from "@mui/material";

const SupportComponent = () => {
  const [iframeUrl, setIframeUrl] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams({
      solution: "BPHDP",
    }).toString();
    const url = `https://support-center.edaa.siemens-energy.cloud/Home?${queryParams}`;
    setIframeUrl(url);
  }, []);

  return (
    <Box className="iframe-container" sx={{ height: "89vh" }}>
      <iframe
        style={{ height: "100%", width: "100%" }}
        src={iframeUrl}
        frameBorder="0"
      ></iframe>
    </Box>
  );
};

export default SupportComponent;

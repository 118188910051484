import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Groups3Icon from "@mui/icons-material/Groups3";
import AddIcon from "@mui/icons-material/Add";

const StyledHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

interface HeaderProps {
  title: string;
  onAdd?: () => void;
  onCreateRelation?: () => void;
  showCreateRelationButton?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  title,
  onAdd,
  onCreateRelation,
  showCreateRelationButton,
}) => (
  <StyledHeader>
    <Typography variant="h6">{title}</Typography>
    <Box>
      {showCreateRelationButton && onCreateRelation && (
        <Button
          variant="contained"
          color="secondary"
          onClick={onCreateRelation}
          startIcon={<Groups3Icon />}
        >
          Manage Direct Relations
        </Button>
      )}
      {onAdd && (
        <Button
          variant="contained"
          color="secondary"
          onClick={onAdd}
          startIcon={<AddIcon />}
          style={{ marginLeft: "10px" }}
        >
          Add Brand
        </Button>
      )}
    </Box>
  </StyledHeader>
);

export default Header;

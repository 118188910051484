import React, {
  useState,
  useCallback,
  useEffect,
  createContext,
  useContext,
  memo,
  ReactNode,
} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { InteractionType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import {
  Header,
  LanguageProvider,
} from "@data-products-and-ai/react-components";
import {
  Box,
  Typography,
  Modal,
  Backdrop,
  Fade,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CONSTANTS } from "./constants/constants";
import SearchPage from "./pages/Search/Search";
import { Login } from "@/pages/Login";
import "./App.scss";
import SupportComponent from "./pages/Search/SupportApp";

interface ModalContextType {
  modalOpen: boolean;
  helpModalOpen: boolean;
  handleModalOpen: () => void;
  handleModalClose: () => void;
  handleHelpModalOpen: () => void;
  handleHelpModalClose: () => void;
}

const ModalContext = createContext<ModalContextType | null>(null);

const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  const handleModalOpen = useCallback(() => setModalOpen(true), []);
  const handleModalClose = useCallback(() => setModalOpen(false), []);
  const handleHelpModalOpen = useCallback(() => setHelpModalOpen(true), []);
  const handleHelpModalClose = useCallback(() => {
    setHelpModalOpen(false);
    localStorage.setItem("helpModalShown", "true");
  }, []);

  const value: ModalContextType = {
    modalOpen,
    helpModalOpen,
    handleModalOpen,
    handleModalClose,
    handleHelpModalOpen,
    handleHelpModalClose,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (context === null) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};

const modalStyle = {
  position: "absolute" as const,
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "80rem",
  height: "90vh",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: "8px",
  padding: "20px",
  overflow: "auto",
};

const paragraphStyle = {
  padding: "1rem 2rem",
};

const closeButtonStyle = {
  position: "absolute" as const,
  top: "10px",
  right: "10px",
};

interface HelpModalProps {
  open: boolean;
  onClose: () => void;
}

const HelpModal: React.FC<HelpModalProps> = memo(({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyle}>
          <IconButton
            aria-label="close"
            sx={closeButtonStyle}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="h2"
            sx={{ textAlign: "justify", mb: 3 }}
          >
            Help
          </Typography>
          <Box sx={{ padding: "1rem 2rem", lineHeight: "1.8" }}>
            <Typography
              variant="h6"
              component="h2"
              sx={{ textAlign: "justify", mb: 3 }}
            >
              Definition
            </Typography>
            <Typography variant="body1" gutterBottom>
              A brand is a name, term, design, symbol, or any other feature that
              distinguishes one seller's specific product, service, or business
              from those of other sellers. Brands are used in business,
              marketing, and advertising for recognition and, importantly, to
              create and store value as brand equity for the object identified,
              to the benefit of the brand's customers, owners, and stakeholders.
            </Typography>

            <Typography
              variant="h6"
              component="h2"
              sx={{ textAlign: "justify", mb: 3, mt: 4 }}
            >
              What is the purpose?
            </Typography>
            <Typography variant="body1" gutterBottom>
              Our SE Strategy Journey: Become a data-driven company that creates
              value through digitalization, automation, and connectivity. From a
              company with Data … to a data-enabled company.
            </Typography>

            <Typography
              variant="h6"
              component="h2"
              sx={{ textAlign: "justify", mb: 3, mt: 4 }}
            >
              WHAT we want to achieve:
            </Typography>
            <Typography variant="body1" gutterBottom>
              We want to act effectively as an organization and collaborate with
              our (Top) Customers to understand what makes the most sense for
              them. We can drive management decisions based on stable and
              reliable data.
            </Typography>

            <Typography
              variant="h6"
              component="h2"
              sx={{ textAlign: "justify", mb: 3, mt: 4 }}
            >
              HOW we want to achieve our goal?
            </Typography>
            <Typography variant="body1" gutterBottom>
              Based on the conceptual data model, we identified BRAND as a
              linking object between Accounts/Business Partners as the first
              step to achieve our goal. Brand relationships will help us to
              understand how enterprises are structured.
            </Typography>

            <Typography
              variant="h6"
              component="h2"
              sx={{ textAlign: "justify", mb: 3, mt: 4 }}
            >
              How to use the tool?
            </Typography>

            <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
              <strong>BRANDS:</strong>
            </Typography>
            <Typography variant="body1" gutterBottom>
              • Shows all BRANDS (will grow over time) that have been identified
              as having any kind of relationship with Siemens Energy.
              <br />• <strong>ADD BRAND:</strong>
              <br />
              ○ Every employee of Siemens Energy can create a BRAND.
              <br />○ New BRAND requests will be validated and if criteria
              match, the new BRAND will be created.
            </Typography>

            <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
              <strong>Related BRANDS:</strong>
            </Typography>
            <Typography variant="body1" gutterBottom>
              • Once a BRAND has been selected, all Related BRANDS will be shown
              under Related Brands.
              <br />
              • Using the Distance (Direct or Indirect) feature will show the
              relationship between the selected BRAND and the Related Brand.
              <br />• <strong>MANAGE DIRECT RELATIONS:</strong> This section is
              accessible only to users with respective rights.
            </Typography>

            <Typography variant="body2" gutterBottom sx={{ mt: 4 }}>
              <strong>Status: 10.09.2024</strong>
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
});

const ContactButton: React.FC = memo(() => {
  const { handleModalOpen } = useModal();
  return (
    <Button
      color="secondary"
      onClick={handleModalOpen}
      key="contact-button"
      sx={{ textTransform: "none" }}
    >
      <Typography sx={{ textAlign: "justify", mb: 0 }}>Contact us</Typography>
    </Button>
  );
});

const HelpButton: React.FC = memo(() => {
  const { handleHelpModalOpen } = useModal();
  return (
    <Button
      color="secondary"
      onClick={handleHelpModalOpen}
      key="help-button"
      sx={{ textTransform: "none", ml: 2 }}
    >
      <Typography sx={{ textAlign: "justify", mb: 0 }}>Help</Typography>
    </Button>
  );
});

const Main: React.FC = memo(() => {
  const {
    modalOpen,
    helpModalOpen,
    handleModalClose,
    handleHelpModalClose,
    handleHelpModalOpen,
  } = useModal();

  useEffect(() => {
    const isFirstTime = localStorage.getItem("helpModalShown");
    if (!isFirstTime) {
      handleHelpModalOpen();
    }
  }, [handleHelpModalOpen]);

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={2}
      >
        <Header
          hasSignOut
          customComponents={[
            <ContactButton key="contact" />,
            <HelpButton key="help" />,
          ]}
        />
      </Box>

      <SearchPage />

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={modalStyle}>
            <IconButton
              aria-label="close"
              sx={closeButtonStyle}
              onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="h2"
              sx={{ textAlign: "justify", mb: 3 }}
            >
              Help and Improvements Request
            </Typography>
            <Box sx={paragraphStyle}>
              <SupportComponent />
            </Box>
          </Box>
        </Fade>
      </Modal>

      <HelpModal open={helpModalOpen} onClose={handleHelpModalClose} />
    </MsalAuthenticationTemplate>
  );
});

const NotFoundPage: React.FC = memo(() => {
  return <>Not found</>;
});

const LoginToggle: React.FC = memo(() => {
  return (
    <LanguageProvider translations={CONSTANTS} defaultLanguage="en">
      <AuthenticatedTemplate>
        <Main />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </LanguageProvider>
  );
});

const App: React.FC = () => {
  return (
    <ModalProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/notfound" element={<NotFoundPage />} />
          <Route path="*" element={<LoginToggle />} />
        </Routes>
      </BrowserRouter>
    </ModalProvider>
  );
};

export default App;

import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Container,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import Header from "./Header";
import BrandSearchComponent from "./BrandSearch";
import RelatedBrandsComponent from "./RelatedBrands";
import NewBrandDialog from "./NewBrandDialog";
import RelationTableComponent from "./RelationTable";
import { Brand } from "@/types/brand";
import { acquireToken } from "@/utils";

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(4),
}));

const SearchPage: React.FC = () => {
  const { instance, accounts } = useMsal();
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [relatedBrands, setRelatedBrands] = useState<Brand[]>([]);
  const [loadingRelated, setLoadingRelatedBrands] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [relationModalOpen, setRelationModalOpen] = useState<boolean>(false);
  const [modifiedRelatedBrands, setModifiedRelatedBrands] = useState<Brand[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const brandSearchRef = useRef<{ fetchData: () => Promise<void> } | null>(
    null
  );

  useEffect(() => {
    if (selectedBrand) {
      getRelatedBrands(selectedBrand.BRAND_ID);
    }
  }, [selectedBrand]);

  const getRelatedBrands = async (id: number) => {
    setLoadingRelatedBrands(true);
    try {
      const tokenResponse = await acquireToken(instance, accounts);

      const headers = {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_API_URL
        }/brandmanagementservice/get_related_brands?selected_brand_id=${id}`,
        { headers }
      );

      //TODO: Remove this when BE stop sending duplicates
      const data = Array.from(
        new Map(
          response.data.data.map((brand: Brand) => [
            brand.BRAND_ID,
            {
              ...brand,
              IS_RELATED: brand.IS_RELATED ?? false,
            },
          ])
        ).values()
      ) as Brand[];
      setRelatedBrands(data);
      setModifiedRelatedBrands(data);
    } catch (error) {
      console.error("Error fetching related brands:", error);
      setSnackbarMessage("Error fetching related brands.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoadingRelatedBrands(false);
    }
  };

  const getPresignedUrl = async (file: File) => {
    try {
      const tokenResponse = await acquireToken(instance, accounts);

      const headers = {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        `${import.meta.env.VITE_APP_API_URL}/filemanagementservice/upload_file`,
        { file_name: file.name },
        { headers }
      );

      return response.data;
    } catch (error) {
      console.error("Error getting presigned URL:", error);
      setSnackbarMessage("Error getting presigned URL.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      throw error;
    }
  };

  const uploadFileToS3 = async (file: File, presignedUrl: any) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(presignedUrl.fields)) {
      formData.append(key, value as string);
    }
    formData.append("file", file);

    try {
      await axios.post(presignedUrl.url, formData);
      return presignedUrl.url + presignedUrl.fields.key;
    } catch (error) {
      console.error("Error uploading file to S3:", error);
      setSnackbarMessage("Error uploading file to S3.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      throw error;
    }
  };

  const handleCreateBrand = async (
    brandName: string,
    brandFile: File | null
  ) => {
    if (!brandFile) {
      setSnackbarMessage("File is required to create a new brand.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    try {
      let fileUrl = null;
      let presignedUrlData = null;
      if (brandFile) {
        try {
          presignedUrlData = await getPresignedUrl(brandFile);
          fileUrl = await uploadFileToS3(
            brandFile,
            presignedUrlData.upload_url
          );
        } catch (error) {
          return;
        }
      }

      console.log(fileUrl);

      const tokenResponse = await acquireToken(instance, accounts);

      const headers = {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      };

      const payload = {
        BRAND_NAME: brandName,
        FILE_PATH: presignedUrlData?.upload_url?.fields?.key,
        IS_APPROVED: true,
        IS_UPDATE: false,
        BRAND_ID: 55,
        USER_ID: (tokenResponse.idTokenClaims as any).gid,
        selected: false,
      };

      await axios.post(
        `${
          import.meta.env.VITE_APP_API_URL
        }/brandmanagementservice/create_new_brand`,
        payload,
        { headers }
      );
      setOpen(false);
      setSnackbarMessage("Brand created successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      if (brandSearchRef.current) {
        brandSearchRef.current.fetchData();
      }
    } catch (error: any) {
      console.error("Error creating brand:", error);
      setSnackbarMessage(error.response?.data?.message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRelationModalOpen = () => setRelationModalOpen(true);
  const handleRelationModalClose = () => setRelationModalOpen(false);

  const handleRelateChange = useCallback((updatedBrands: Brand[]) => {
    setModifiedRelatedBrands(updatedBrands);
  }, []);

  const handleSubmitSuccess = useCallback(() => {
    getRelatedBrands(selectedBrand?.BRAND_ID!);
    setRelationModalOpen(false);
  }, [selectedBrand]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <StyledContainer>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Header title="Brands" onAdd={handleOpen} />
          <BrandSearchComponent
            instance={instance}
            accounts={accounts}
            onBrandSelect={setSelectedBrand}
            ref={brandSearchRef}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Header
            title="Related Brands"
            onCreateRelation={handleRelationModalOpen}
            showCreateRelationButton={!!selectedBrand}
          />
          <RelatedBrandsComponent
            selectedBrand={selectedBrand}
            relatedBrands={relatedBrands}
            loadingRelated={loadingRelated}
          />
        </Grid>
      </Grid>
      <NewBrandDialog
        open={open}
        onClose={handleClose}
        onCreate={handleCreateBrand}
      />
      <Dialog
        open={relationModalOpen}
        onClose={handleRelationModalClose}
        fullWidth
        maxWidth="xl"
        PaperProps={{ style: { height: "80vh" } }}
      >
        <DialogTitle>
          Manage Direct Relations
          <IconButton
            aria-label="close"
            onClick={handleRelationModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedBrand && (
            <RelationTableComponent
              selectedBrand={selectedBrand}
              onRelateChange={handleRelateChange}
              onCancel={() => setRelationModalOpen(false)}
              onSubmitSuccess={handleSubmitSuccess}
              modifiedRelatedBrands={modifiedRelatedBrands}
              setModifiedRelatedBrands={setModifiedRelatedBrands}
            />
          )}
        </DialogContent>
      </Dialog>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </div>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
};

export default SearchPage;

import React from "react";
import {
  Paper,
  Typography,
  IconButton,
  Chip,
  Box,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Brand } from "@/types/brand"; // Ensure this import is correct

interface BrandCardProps {
  brand: Brand;
  onEyeClick: (brand: Brand) => void;
  onCardClick: (brand: Brand) => void;
  selected?: boolean;
}

const StyledBrandCard = styled(Paper)<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
    textAlign: "left",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${
      selected ? theme.palette.primary.main : theme.palette.divider
    }`,
    boxShadow: "none",
    flexDirection: "column",
    transition: "0.3s",
    cursor: "pointer",
    backgroundColor: selected ? theme.palette.action.selected : "transparent",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  })
);

const BrandCard: React.FC<BrandCardProps> = ({
  brand,
  onEyeClick,
  onCardClick,
  selected,
}) => {
  const handleEyeClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onEyeClick(brand);
  };

  const handleCardClick = () => {
    onCardClick(brand);
  };

  const getChipColor = (distance: string) => {
    switch (distance) {
      case "1":
        return "success";
      case "2":
        return "warning";
      case "3":
        return "error";
      default:
        return "default";
    }
  };

  return (
    <StyledBrandCard selected={selected} onClick={handleCardClick}>
      <Box
        display="flex"
        sx={{ width: "100%" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">{brand.BRAND_NAME}</Typography>
        <IconButton onClick={handleEyeClick}>
          <VisibilityIcon />
        </IconButton>
      </Box>
      {brand.DISTANCE && (
        <Tooltip
          title={
            <>
              <Typography variant="body2">
                Relationship distance: {brand.DISTANCE}
              </Typography>
              <Typography variant="body2">
                Related to: {brand.ORIGIN_BRAND_NAME}
              </Typography>
            </>
          }
          arrow
        >
          <Chip
            sx={{ margin: 1 }}
            color={getChipColor(brand.DISTANCE)}
            size="small"
            label={
              brand.DISTANCE === "1" ? "direct" : `indirect ${brand.DISTANCE}`
            }
          />
        </Tooltip>
      )}
    </StyledBrandCard>
  );
};

export default BrandCard;

import React, {
  useState,
  ChangeEvent,
  DragEvent,
  MouseEvent,
  useRef,
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormHelperText,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

interface NewBrandDialogProps {
  open: boolean;
  onClose: () => void;
  onCreate: (brandName: string, brandFile: File | null) => void;
}

const ALLOWED_FILE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/svg+xml",
  "image/ico",
  "image/gif",
  "image/tiff",
  "image/bmp",
];
const MAX_FILE_SIZE_MB = 5;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

const NewBrandDialog: React.FC<NewBrandDialogProps> = ({
  open,
  onClose,
  onCreate,
}) => {
  const [newBrandName, setNewBrandName] = useState<string>("");
  const [brandFile, setBrandFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string>("");
  const [filePreview, setFilePreview] = useState<string | null>(null);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    processFile(file);
  };

  const processFile = (file: File | null) => {
    if (file) {
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        setFileError(
          "Invalid file type. Only JPEG, PNG, SVG, ICO, GIF, TIFF, and BMP are allowed."
        );
        setBrandFile(null);
        setFilePreview(null);
      } else if (file.size > MAX_FILE_SIZE_BYTES) {
        setFileError("File size exceeds 5 MB limit.");
        setBrandFile(null);
        setFilePreview(null);
      } else {
        setFileError("");
        setBrandFile(file);
        setFilePreview(URL.createObjectURL(file)); // Set the preview URL
      }
    } else {
      setFileError("");
      setBrandFile(null);
      setFilePreview(null);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    processFile(file);
  };

  const handleCreateBrand = () => {
    if (!brandFile) {
      setFileError("File is required.");
      return;
    }
    if (fileError) {
      return;
    }
    onCreate(newBrandName, brandFile);
    handleReset();
    onClose();
  };

  const handleReset = () => {
    setNewBrandName("");
    setBrandFile(null);
    setFileError("");
    setFilePreview(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleClearFile = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setBrandFile(null);
    setFilePreview(null);
    setFileError("");

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleClose = () => {
    handleReset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Add Brand{" "}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <TextField
          autoFocus
          margin="dense"
          label="Brand Name"
          type="text"
          fullWidth
          value={newBrandName}
          onChange={(e) => setNewBrandName(e.target.value)}
        />
        <Typography variant="subtitle1">Proof</Typography>
        <Box
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          sx={{
            border: "2px dashed grey",
            padding: "2rem",
            textAlign: "center",
            cursor: "pointer",
            backgroundColor: "#fafafa",
            borderRadius: "4px",
            position: "relative",
          }}
        >
          <input
            accept={ALLOWED_FILE_TYPES.join(",")}
            style={{ display: "none" }}
            id="file-input"
            type="file"
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          <label htmlFor="file-input">
            {filePreview ? (
              <Box position="relative">
                <img
                  src={filePreview}
                  alt="Preview"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "200px",
                    marginBottom: "1rem",
                  }}
                />
                <IconButton
                  onClick={handleClearFile}
                  sx={{
                    position: "absolute",
                    top: "4rem",
                    right: "-5rem",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <Typography variant="body2">
                  {brandFile?.name} -{" "}
                  {brandFile ? (brandFile.size / 1024).toFixed(2) : "0"} KB
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CloudUploadIcon sx={{ fontSize: 48, color: "gray" }} />
                <Typography>
                  Drag and drop an image file here or click
                </Typography>
                <Typography variant="body2">
                  (JPEG, PNG, SVG, ICO, GIF, TIFF, and BMP are allowed)
                </Typography>
              </Box>
            )}
          </label>
        </Box>
        {fileError && <FormHelperText error>{fileError}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleReset}
          variant="text"
          sx={{ marginRight: "auto", ml: 2 }}
        >
          Reset
        </Button>
        <Button onClick={handleClose} variant="contained" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleCreateBrand}
          variant="contained"
          disabled={!newBrandName || !brandFile || fileError !== ""}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewBrandDialog;

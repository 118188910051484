import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import {
  DataGrid,
  GridRenderCellParams,
  GridFilterModel,
  GridPaginationModel,
} from "@mui/x-data-grid";
import {
  Box,
  CircularProgress,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Popover,
  IconButton,
  Badge,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { Brand } from "@/types/brand";
import { acquireToken } from "@/utils";
import PostAddIcon from "@mui/icons-material/PostAdd";
import InfoIcon from "@mui/icons-material/Info";

const TIMER_DURATION = 60;
const BLOCK_DIALOG_SHOW_TIME = 10;

interface RelationTableComponentProps {
  selectedBrand: Brand;
  onRelateChange: (updatedBrands: Brand[]) => void;
  onSubmitSuccess: () => void;
  onCancel: () => void;
  modifiedRelatedBrands: Brand[];
  setModifiedRelatedBrands: React.Dispatch<React.SetStateAction<Brand[]>>;
}

const RelationTableComponent: React.FC<RelationTableComponentProps> = ({
  selectedBrand,
  onRelateChange,
  onSubmitSuccess,
  onCancel,
  modifiedRelatedBrands,
  setModifiedRelatedBrands,
}) => {
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [_totalRowCount, setTotalRowCount] = useState<number>(0);
  const [_allChecked, setAllChecked] = useState<boolean>(false);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [confirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const [blockDialogOpen, setBlockDialogOpen] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState<number>(TIMER_DURATION);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const originalRelatedBrandsRef = useRef<Brand[]>([]);
  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const blockDialogTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [selectedItems, setSelectedItems] = useState<Brand[]>([]);
  const [filteredRows, setFilteredRows] = useState<Brand[]>([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const paginationModelRef = useRef<GridPaginationModel>({
    page: 0,
    pageSize: 15,
  });

  const fetchRelatedBrands = useCallback(async () => {
    if (!selectedBrand) return;

    setLoading(true);
    try {
      const tokenResponse = await acquireToken(instance, accounts);

      const headers = {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_API_URL
        }/brandmanagementservice/get_available_brands`,
        {
          params: {
            selected_brand_id: selectedBrand.BRAND_ID,
            limit: 1500,
          },
          headers,
        }
      );

      const data = Array.from(
        new Map(
          response.data.data.map((brand: Brand) => [
            brand.BRAND_ID,
            {
              ...brand,
              IS_RELATED: brand.IS_RELATED ?? false,
            },
          ])
        ).values()
      ) as Brand[];

      setModifiedRelatedBrands(data);
      setTotalRowCount(response.data.n_brands_returned || 0);
      setAllChecked(data.every((brand: Brand) => brand.IS_RELATED));
      originalRelatedBrandsRef.current = JSON.parse(JSON.stringify(data));

      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) =>
          data.some(
            (brand: { BRAND_ID: number }) => brand.BRAND_ID === item.BRAND_ID
          )
        )
      );
    } catch (error) {
      console.error("Error fetching related brands:", error);
      setTotalRowCount(0);
    } finally {
      setLoading(false);
    }
  }, [selectedBrand, instance, accounts, setModifiedRelatedBrands]);

  const manageBlockedBrands = async (isBlockOperation: boolean) => {
    try {
      const tokenResponse = await acquireToken(instance, accounts);

      const headers = {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      };

      await axios.get(
        `${
          import.meta.env.VITE_APP_API_URL
        }/brandmanagementservice/manage_blocked_brands`,
        {
          params: {
            selected_brand_id: selectedBrand.BRAND_ID,
            user_id: (tokenResponse.idTokenClaims as any).gid,
            is_block_operation: isBlockOperation,
            default_block_minutes: 5,
          },
          headers,
        }
      );
    } catch (error: any) {
      setErrorMessage(error.response.data.message);
      console.error("Error managing blocked brands:", error);
    }
  };

  useEffect(() => {
    if (selectedBrand) {
      manageBlockedBrands(true);
      fetchRelatedBrands();

      blockDialogTimeoutRef.current = setTimeout(() => {
        setBlockDialogOpen(true);
      }, (TIMER_DURATION - BLOCK_DIALOG_SHOW_TIME) * 1000);

      const countdownInterval = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime === 1) {
            clearInterval(countdownInterval);
            handleStopEditing();
          }
          return prevTime - 1;
        });
      }, 1000);

      countdownIntervalRef.current = countdownInterval;

      return () => {
        if (countdownIntervalRef.current) {
          clearInterval(countdownIntervalRef.current);
        }
        if (blockDialogTimeoutRef.current) {
          clearTimeout(blockDialogTimeoutRef.current);
        }
        manageBlockedBrands(false);
      };
    }
    return;
  }, [selectedBrand, fetchRelatedBrands]);

  const handleRelateChange = useCallback(
    (brandId: number) => {
      const updateBrands = (brands: Brand[]) =>
        brands.map((brand) =>
          brand.BRAND_ID === brandId
            ? { ...brand, IS_RELATED: !brand.IS_RELATED }
            : brand
        );

      setModifiedRelatedBrands((prevBrands) => {
        const updatedBrands = updateBrands(prevBrands);

        if (isFiltered) {
          setFilteredRows((prevFilteredRows) => updateBrands(prevFilteredRows));
        }

        const selectedBrand = updatedBrands.find(
          (brand) => brand.BRAND_ID === brandId
        );
        if (selectedBrand?.IS_RELATED) {
          setSelectedItems((prev) => [...prev, selectedBrand]);
        } else {
          setSelectedItems((prev) =>
            prev.filter((item) => item.BRAND_ID !== brandId)
          );
        }

        onRelateChange(updatedBrands);
        setIsModified(
          JSON.stringify(originalRelatedBrandsRef.current) !==
            JSON.stringify(updatedBrands)
        );
        return updatedBrands;
      });
    },
    [onRelateChange, isFiltered]
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      const filteredData = modifiedRelatedBrands.filter((brand) => {
        return filterModel.items.every((filter) => {
          if (!filter.value) return true;
          const value = brand[filter.field as keyof Brand]
            ?.toString()
            .toLowerCase();
          return value?.includes(filter.value.toLowerCase());
        });
      });
      setFilteredRows(filteredData);
      setIsFiltered(filterModel.items.some((filter) => filter.value));
    },
    [modifiedRelatedBrands]
  );

  const handleCheckAll = useCallback(() => {
    const { page, pageSize } = paginationModelRef.current;
    const start = page * pageSize;
    const end = start + pageSize;
    const currentRows = isFiltered ? filteredRows : modifiedRelatedBrands;
    const currentVisibleRows = currentRows.slice(start, end);

    const allCurrentlyRelated = currentVisibleRows.every(
      (brand) => brand.IS_RELATED
    );

    const updatedVisibleRows = currentVisibleRows.map((brand) => ({
      ...brand,
      IS_RELATED: !allCurrentlyRelated,
    }));

    setModifiedRelatedBrands((prevBrands) => {
      const updatedBrands = prevBrands.map((brand) => {
        const updatedVisibleRow = updatedVisibleRows.find(
          (row) => row.BRAND_ID === brand.BRAND_ID
        );
        return updatedVisibleRow || brand;
      });

      if (isFiltered) {
        setFilteredRows((prevFilteredRows) =>
          prevFilteredRows.map((brand) => {
            const updatedVisibleRow = updatedVisibleRows.find(
              (row) => row.BRAND_ID === brand.BRAND_ID
            );
            return updatedVisibleRow || brand;
          })
        );
      }

      const newSelectedItems = updatedBrands.filter(
        (brand) => brand.IS_RELATED
      );
      setSelectedItems(newSelectedItems);

      onRelateChange(updatedBrands);
      setIsModified(
        JSON.stringify(originalRelatedBrandsRef.current) !==
          JSON.stringify(updatedBrands)
      );
      return updatedBrands;
    });

    setAllChecked(!allCurrentlyRelated);
  }, [isFiltered, filteredRows, modifiedRelatedBrands, onRelateChange]);

  const handleReset = () => {
    setModifiedRelatedBrands([...originalRelatedBrandsRef.current]);
    setSelectedItems([]);
    setIsModified(false);
  };

  const handleSubmit = async () => {
    const updatesInBrands = modifiedRelatedBrands
      .filter((brand) => {
        const original = originalRelatedBrandsRef.current.find(
          (b) => b.BRAND_ID === brand.BRAND_ID
        );
        return original && original.IS_RELATED !== brand.IS_RELATED;
      })
      .map((brand) => ({
        RELATED_BRAND_ID: brand.BRAND_ID,
        WAS_RELATED: originalRelatedBrandsRef.current.find(
          (b) => b.BRAND_ID === brand.BRAND_ID
        )!.IS_RELATED,
        IS_RELATED: brand.IS_RELATED,
      }));

    if (updatesInBrands.length === 0) {
      setConfirmationModalOpen(false);
      return;
    }

    const payload = {
      SELECTED_BRAND_ID: selectedBrand.BRAND_ID,
      UPDATES_IN_BRANDS: updatesInBrands,
      LAST_RELATION_REASON: reason,
      USER_ID: "",
      NAME: "",
      EMAIL: "",
    };

    setSubmitting(true);
    try {
      const tokenResponse = await acquireToken(instance, accounts);

      payload.USER_ID = (tokenResponse.idTokenClaims as any).gid;
      payload.NAME = (tokenResponse as any).account.name;
      payload.EMAIL = (tokenResponse as any).account.username;

      const headers = {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      };

      await axios.post(
        `${
          import.meta.env.VITE_APP_API_URL
        }/brandmanagementservice/update_brand_relation`,
        payload,
        { headers }
      );

      originalRelatedBrandsRef.current = JSON.parse(
        JSON.stringify(modifiedRelatedBrands)
      );
      setIsModified(false);
      setSelectedItems([]);
      onSubmitSuccess();
    } catch (error) {
      console.error("Error updating related brands:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleConfirmationModalOpen = () => {
    setConfirmationModalOpen(true);
  };

  const handleConfirmationModalClose = (confirm: boolean) => {
    setConfirmationModalOpen(false);
    if (confirm) {
      handleSubmit();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleContinueEditing = () => {
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }
    if (blockDialogTimeoutRef.current) {
      clearTimeout(blockDialogTimeoutRef.current);
    }

    manageBlockedBrands(true);
    setBlockDialogOpen(false);
    setRemainingTime(TIMER_DURATION);

    blockDialogTimeoutRef.current = setTimeout(() => {
      setBlockDialogOpen(true);
    }, (TIMER_DURATION - BLOCK_DIALOG_SHOW_TIME) * 1000);

    const countdownInterval = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime === 1) {
          clearInterval(countdownInterval);
          handleStopEditing();
        }
        return prevTime - 1;
      });
    }, 1000);

    countdownIntervalRef.current = countdownInterval;
  };

  const handleStopEditing = () => {
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }
    if (blockDialogTimeoutRef.current) {
      clearTimeout(blockDialogTimeoutRef.current);
    }
    manageBlockedBrands(false);
    setBlockDialogOpen(false);
    onCancel();
  };

  const modifiedBrandsCount = useMemo(() => {
    return modifiedRelatedBrands.filter((brand) => {
      const original = originalRelatedBrandsRef.current.find(
        (b) => b.BRAND_ID === brand.BRAND_ID
      );
      return original && original.IS_RELATED !== brand.IS_RELATED;
    }).length;
  }, [modifiedRelatedBrands]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const columns: any[] = useMemo(
    () => [
      {
        field: "BRAND_NAME",
        headerName: "Available Brands",
        flex: 1,
        sortable: true,
        filterable: true,
        minWidth: 150,
      },
      {
        field: "IS_BLOCKED",
        headerName: "Is Blocked?",
        renderHeader: () => (
          <div style={{ display: "flex", alignItems: "center" }}>
            Is Blocked?
            <Tooltip title="This indicates whether the brand relationship is being edited by another user">
              <IconButton size="small" sx={{ marginLeft: "4px" }}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        ),
        flex: 1,
        sortable: true,
        filterable: true,
        type: "boolean",
        minWidth: 120, // Add minimum width to prevent cutoff
      },
      {
        field: "BLOCKED_BY",
        headerName: "Blocked by",
        flex: 1,
        sortable: true,
        filterable: true,
        minWidth: 120,
        renderCell: (params: GridRenderCellParams) => (
          <span>{params.value ? params.value : "-"}</span>
        ),
      },
      {
        field: "IS_RELATED",
        headerName: "Is direct related?",
        flex: 1,
        sortable: true,
        filterable: true,
        type: "boolean",
        minWidth: 140, // Increased to prevent truncation
      },
      {
        field: "LAST_RELATION_BY",
        headerName: "Updated by",
        flex: 1,
        sortable: true,
        filterable: true,
        renderCell: (params: GridRenderCellParams) => {
          const name = params.row.NAME || "-";
          const lastRelationBy = params.value || "-";
          return (
            <div>
              <div>{`${name} (${lastRelationBy})`}</div>
            </div>
          );
        },
      },
      // {
      //   field: "LAST_RELATION_REASON",
      //   headerName: "Last Relation Reason",
      //   flex: 1,
      //   sortable: true,
      //   filterable: true,
      //   renderCell: (params: GridRenderCellParams) => (
      //     <span>{params.value ? params.value : "-"}</span>
      //   ),
      // },
      {
        field: "Relate",
        headerName: "Relate directly",
        flex: 0.5,
        minWidth: 200,
        renderHeader: () => {
          const { page, pageSize } = paginationModelRef.current;
          const start = page * pageSize;
          const end = start + pageSize;
          const currentRows = isFiltered ? filteredRows : modifiedRelatedBrands;
          const currentVisibleRows = currentRows.slice(start, end);

          return (
            <div>
              <Checkbox
                checked={currentVisibleRows.every((brand) => brand.IS_RELATED)}
                onChange={handleCheckAll}
                indeterminate={
                  currentVisibleRows.some((brand) => brand.IS_RELATED) &&
                  !currentVisibleRows.every((brand) => brand.IS_RELATED)
                }
              />
              Relate directly
            </div>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          const original = originalRelatedBrandsRef.current.find(
            (b) => b.BRAND_ID === params.row.BRAND_ID
          );
          const isNewChange =
            original && original.IS_RELATED !== params.row.IS_RELATED;

          return (
            <Checkbox
              checked={!!params.row.IS_RELATED}
              onChange={() => handleRelateChange(params.row.BRAND_ID)}
              disabled={!!params.row.IS_BLOCKED}
              sx={{
                color: isNewChange ? "blue" : "green",
                "&.Mui-checked": {
                  color: isNewChange ? "blue" : "green",
                },
              }}
            />
          );
        },
      },
    ],
    [
      isFiltered,
      filteredRows,
      modifiedRelatedBrands,
      handleCheckAll,
      handleRelateChange,
    ]
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap="2rem"
        flexWrap="wrap"
        border={1}
        borderColor="rgb(224, 224, 224)"
        borderBottom={0}
        p={2}
        mb={0}
        position="relative"
        style={{ borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}
      >
        <h2 style={{ margin: 0 }}>
          Selected Brand: {selectedBrand.BRAND_NAME}
        </h2>
        <Box
          display="flex"
          alignItems="center"
          style={{ flexWrap: "wrap", gap: "1rem" }}
        >
          <Box display="flex" alignItems="center">
            <Checkbox
              checked
              sx={{
                color: "green",
                "&.Mui-checked": { color: "green" },
              }}
            />
            Existing Relation
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              checked
              sx={{
                color: "blue",
                "&.Mui-checked": { color: "blue" },
              }}
            />
            New Change
          </Box>
          {selectedItems.length > 0 && (
            <IconButton
              aria-describedby={id}
              onClick={handleClick}
              sx={{ marginLeft: "auto" }}
            >
              <Badge badgeContent={selectedItems.length} color="primary">
                <PostAddIcon />
              </Badge>
            </IconButton>
          )}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box p={2}>
              <h3>Selected Items</h3>
              <ul>
                {selectedItems.map((item) => (
                  <li key={item.BRAND_ID}>{item.BRAND_NAME}</li>
                ))}
              </ul>
            </Box>
          </Popover>
        </Box>
      </Box>
      <div style={{ flexGrow: 1, overflow: "auto", position: "relative" }}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : errorMessage ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <p>{errorMessage}</p>
          </Box>
        ) : (
          <DataGrid
            rows={isFiltered ? filteredRows : modifiedRelatedBrands}
            columns={columns}
            pageSizeOptions={[15, 30, 45]}
            paginationModel={paginationModelRef.current}
            onPaginationModelChange={(model) => {
              paginationModelRef.current = model;
            }}
            getRowId={(row) => row.BRAND_ID}
            hideFooterSelectedRowCount
            disableRowSelectionOnClick
            disableColumnSelector
            disableDensitySelector
            onFilterModelChange={handleFilterModelChange}
            getRowClassName={(params: any) =>
              params.row.IS_BLOCKED ? "blocked-row" : ""
            }
            sx={{
              "& .MuiDataGrid-root": {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-footerContainer": {
                position: "sticky",
                bottom: 0,
                backgroundColor: "white",
                zIndex: 1,
              },
              "& .MuiDataGrid-columnHeaders": {
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                zIndex: 1,
                lineHeight: "normal",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                textOverflow: "unset",
                lineHeight: "1.5rem",
              },
            }}
          />
        )}
      </div>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleReset}
          disabled={!isModified || submitting}
        >
          Reset
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirmationModalOpen}
          style={{ marginLeft: "10px" }}
          disabled={!isModified || submitting}
        >
          Submit
        </Button>
      </DialogActions>
      <Dialog
        open={confirmationModalOpen}
        onClose={() => handleConfirmationModalClose(false)}
      >
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want to update {modifiedBrandsCount} brands?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            sx={{ marginTop: 2 }}
            label="Reason"
            fullWidth
            variant="outlined"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleConfirmationModalClose(false)}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmationModalClose(true)}
            variant="contained"
            color="primary"
            autoFocus
            disabled={!reason.trim()}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={blockDialogOpen} onClose={handleStopEditing}>
        <DialogTitle>Continue Editing?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your session is about to expire in {remainingTime} seconds. Do you
            want to continue editing the brand?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleStopEditing}
            variant="contained"
            color="secondary"
          >
            No
          </Button>
          <Button
            onClick={handleContinueEditing}
            variant="contained"
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {submitting && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bgcolor="rgba(255, 255, 255, 0.8)"
          zIndex={9999}
        >
          <CircularProgress />
        </Box>
      )}
      <style>
        {`
          .blocked-row {
            background-color: #f0f0f0;
            color: #999999;
          }
          .blocked-row .MuiCheckbox-root {
            color: #999999;
          }
        `}
      </style>
    </div>
  );
};

export default RelationTableComponent;

const LoginTitle = () => {
  return (
    <div
      style={{
        marginTop: 10,
        position: 'relative',
        height: 170,
      }}
    >
      <div
        style={{
          position: 'absolute',
          fontSize: 20,
          top: 16,
          left: 102,
          letterSpacing: 0.5,
        }}
      >
        BPHDP
      </div>
    </div>
  );
};

export default LoginTitle;

//type TranslationsType = Record<string, any>;
export type TranslationsType = typeof CONSTANTS;
export const CONSTANTS = {
  en: {
    LOGIN: {
      BUTTON: "Login with Azure AD",
      REQUEST_ACCESS: "Request access",
      SUPPORT: "Support",
      REQUEST_ACCESS_EMAIL_SUBJECT: "Request access from BPHDP",
    },
  },
  de: {
    LOGIN: {
      BUTTON: "Login mit Azure AD",
      REQUEST_ACCESS: "Anfrage Zugang",
      SUPPORT: "Unterstützung",
      REQUEST_ACCESS_EMAIL_SUBJECT: "Request access from BPHDP",
    },
  },
};

export const CATEGORIES = {
  LABELS: {
    PRODUCTION_ORDERS: "Production Orders",
    PRODUCTS: "Products",
    ADDITIONAL_EQUIPMENT: "Additional equipment",
    OPERATIONS: "Operations",
    PAUSES: "Pauses",
    SETS_ADDITIONAL_EQUIPMENT: "Sets of additional equipment",
    SET_PAUSES: "Sets of pauses",
    SET_WORK_UNITS: "Sets of work units",
    SHIFTS: "Shifts",
    SHIFTS_CALENDAR: "Shifts calendar",
    SHUTDOWNS: "Shutdowns",
    WORK_PLANS: "Work plans",
    WORK_UNITS: "Work units",
  },
};

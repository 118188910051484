import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { acquireToken } from "@/utils";
import { Brand } from "@/types/brand";
import { DataGrid } from "@mui/x-data-grid";

interface AccountsTableProps {
  brand: Brand;
}

interface AccountData {
  ACCOUNT_ID: string;
  ACCOUNT_NAME: string;
  BILLING_COUNTRY: string;
  BILLING_CITY: string;
  OWNER_ID: string;
  ROW_ID: number;
}

const AccountsTable: React.FC<AccountsTableProps> = ({ brand }) => {
  const [brandAccounts, setBrandAccounts] = useState<AccountData[] | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  const { instance, accounts } = useMsal();

  const fetchBrandAccounts = async () => {
    try {
      setLoading(true);
      const tokenResponse = await acquireToken(instance, accounts);
      const headers = {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_API_URL
        }/brandmanagementservice/get_related_accounts`,
        {
          params: { selected_brand_id: brand.BRAND_ID },
          headers,
        }
      );
      setBrandAccounts(response.data?.data?.RELATED_ACCOUNTS || []);
    } catch (error) {
      console.error("Error fetching brand's account:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBrandAccounts();
  }, [brand]);

  const columns = [
    { field: "ACCOUNT_NAME", headerName: "Account Name", flex: 1 },
    { field: "BILLING_COUNTRY", headerName: "Country", flex: 1 },
    { field: "BILLING_CITY", headerName: "City", flex: 1 },
    { field: "OWNER_ID", headerName: "Owner", flex: 1 },
  ];

  return (
    <Box>
      {loading ? (
        <Box display="flex" justifyContent="center" padding={2}>
          <CircularProgress />
        </Box>
      ) : brandAccounts && brandAccounts.length > 0 ? (
        <div style={{ width: "100%", height: "50vh" }}>
          <DataGrid
            rows={brandAccounts.map((row) => ({
              id: row.ROW_ID,
              ...row,
            }))}
            disableRowSelectionOnClick
            sx={{
              "& .MuiDataGrid-root": {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-footerContainer": {
                position: "sticky",
                bottom: 0,
                backgroundColor: "white",
                zIndex: 1,
              },
              "& .MuiDataGrid-columnHeaders": {
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                zIndex: 1,
                lineHeight: "normal",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                textOverflow: "unset",
                lineHeight: "1.5rem",
              },
            }}
            columns={columns}
            loading={loading}
            sortingOrder={["asc", "desc"]}
          />
        </div>
      ) : (
        <Box padding={2}>
          <Typography variant="body1" align="center">
            No accounts found for this brand.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default AccountsTable;

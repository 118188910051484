import { msalConfig } from "./config/authConfig";

/**
 * Function to acquire a token using MSAL.
 * @param {IPublicClientApplication} instance - The MSAL instance.
 * @param {AccountInfo[]} accounts - The MSAL accounts.
 * @param {string[]} scopes - The scopes for which the token is requested.
 * @returns {Promise<string>} - A promise that resolves to the access token.
 */
export const acquireToken = async (
  instance: {
    acquireTokenSilent: (arg0: {
      scopes: string[];
      account: any;
      forceRefresh: boolean;
    }) => any;
    acquireTokenRedirect: (arg0: { scopes: string[] }) => any;
  },
  accounts: any[],
  scopes = [`${msalConfig.auth.clientId}/.default`]
) => {
  try {
    let tokenResponse;
    try {
      tokenResponse = await instance.acquireTokenSilent({
        scopes: scopes,
        account: accounts[0],
        forceRefresh: true, // Ensure a fresh token is fetched
      });
    } catch (error) {
      tokenResponse = await instance.acquireTokenRedirect({
        scopes: scopes,
      });
    }
    return tokenResponse;
  } catch (error) {
    console.error("Error acquiring token:", error);
    throw error;
  }
};
